<script setup>
import {ref} from "vue";

const props = defineProps({
    value: String,
    size: {
        type: Number,
        default: 1
    },
})

const textSize = props.size

const $styles = ref({
    width: (2 * textSize) + 'rem',
    height: (2 * textSize) + 'rem',
    lineHeight: textSize + 'rem',
    fontSize: textSize + 'rem',
    padding: (props.size / 10) + 'rem',
})
</script>
<template>
    <div class="circle-tag">
        <div class="circle-tag-item flex align-items-center justify-content-center" :style="$styles">
            <div class="block w-full text-center">
                {{ value }}
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.circle-tag {
}
</style>
