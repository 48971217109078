<script setup>
import Navigation from "@/components/Navigation.vue";
import {computed} from "vue";
import {useRouter} from "vue-router";
import {useAuth} from "@/stores/auth";

const $router = useRouter();

const items = {
    trainees: {
        label: 'Stagiaires',
        icon: 'mdi mdi-account-group',
        to: {name: 'trainees'},
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        },
    },
    organizations: {
        label: 'Organismes',
        icon: 'mdi mdi-domain',
        to: {name: 'organizations'},
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        },
    },
    users: {
        label: 'Utilisateurs',
        icon: 'mdi mdi-account-multiple-outline',
        to: {name: 'users'},
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        },
    },
    profile: {
        label: "Profil",
        to: {name: 'profile'},
        icon: 'mdi mdi-account-settings',
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        },
    },
    settings: {
        label: "Réglages",
        to: {name: 'settings'},
        icon: 'mdi mdi-cog',
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        },
    },
}

const traineeItems = {
    trainees: {
        label: 'Stagiaires',
        icon: 'mdi mdi-account-group',
        back: true,
        to: {name: 'trainees'},
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        }
    },
    traineeCv: {
        label: 'CV',
        icon: 'mdi mdi-card-account-details-outline',
        to: {name: 'trainee-cv'},
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        }
    },
    traineeSkills: {
        label: 'Compétences',
        icon: 'mdi mdi-clipboard-check-multiple-outline',
        to: {name: 'trainee-skills'},
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        }
    },
    traineeEmployers: {
        label: 'Employeurs',
        icon: 'mdi mdi-domain',
        to: {name: 'trainee-employers'},
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        }
    },
}

const menuItems = computed( () => {

    if($router.currentRoute.value.matched[0] && $router.currentRoute.value.matched[0].name === 'trainee') {
        return traineeItems
    }

    return items
})

</script>
<template>
    <Navigation :items="menuItems"></Navigation>
</template>
