<script setup>
import {computed} from 'vue'

const props = defineProps({
    class: {
        type: [String],
        default: '',
    },
    errors: {
        default: '',
        required: true,
    },
    multiple: {
        type: [Boolean],
        default: true,
    },
})

const $errors = computed(() => {
    if (!props.errors) return []
    if (Array.isArray(props.errors)) {
        if (props.errors.length > 0) {
            return props.errors
        }
        return []
    }
    return [{$message: props.errors}]
})

const hasErrors = computed(() => $errors.value && $errors.value.length > 0)

const classes = computed(() => 'form-field-error text-sm ml-1 mr-1 ' + props.class)
</script>
<style scoped lang="scss">
.form-field-error {
    color: var(--secondary-600);
}
</style>
<template>
    <div :class="classes" v-if="hasErrors && $errors.length > 1 && multiple">
        <ul class="pl-3">
            <li v-for="error in $errors" :key="error">{{ error.$message }}</li>
        </ul>
    </div>
    <div :class="classes" v-else-if="hasErrors">
        {{ $errors[0].$message }}
    </div>
</template>
