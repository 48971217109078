<script setup>

import {useMain} from "@/stores/main";
import {useAppUpdate} from "@/composables/useAppUpdate";

const mainStore = useMain()
const { updateExists, refreshApp, refreshing } = useAppUpdate()

</script>

<template>
    <div v-if="updateExists" class="md:mr-2 text-xs">
        <i class="mdi mdi-reload"/> Mise à jour disponible.<a href="" class="text-white underline hover font-bold text-right"
                                                            @click="refreshApp">Rafraichir</a></div>
    <div v-if="refreshing" class="mr-2">
        <i class="mdi mdi-reload mdi-spin text-sm"/> Mise à jour ...
    </div>
    <div v-if="! mainStore.isNetworkConnected" class="mr-2">
        <i class="mdi mdi-lan-disconnect"/> Hors ligne
    </div>

</template>

<style scoped lang="scss">

</style>
